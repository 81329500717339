@use "../../Variables.scss" as variable;
.team-element {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
    color: variable.$black;

    .icon  {
        border: 2px solid #673AB7;
        // border-radius: 26px;
        font-size: 20px;
        height: 48px;
        width: 48px;
        text-align: center;
        color: variable.$black;
        background: variable.$inner-color;
        // vertical-align: middle;
        display: table-cell;
        display: flex;
        align-items: center;
        justify-content: center;


        &.software {
            border-color: variable.$software;
        }

        &.agile {
            border-color: variable.$agile;
        }

        &.design {
            border-color: variable.$design;
        }
    }

    .content {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 8px;
    }

    .number {
        width: 12px;
        text-align: center;
    }

    .x {

    }

    .text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 2px;   
        max-width: 200px;
        
    }

    .title {

    }

    .subTitle {
        color: variable.$muted;
    }
}