.mr-home-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
}

.home-hero-text {
    padding-left: 8px;
    padding-right: 8px;
}