@use "../../Variables.scss" as variable;

.persona {
    .persona-img {
        padding: 32px;
        background: variable.$background;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 6px;
        margin-bottom: 6px;

        img {
            margin: auto;
            height: 150px;
            width: auto; 
        }
    }

    ul {
        margin-top: 0;
        margin-bottom: 0;
    }
}
