@use "../../Variables.scss" as variable;


.ref-image {

    img {
        width: 100%;
        margin-top: 16px;
        box-shadow: rgba(80, 80, 80, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    }

    .caption  {
        font-style: italic;
        width: 100%;
        text-align: center;
        margin: 16px
    }
}