@use "../../Variables.scss" as variable;

h1 {
    font-size: variable.$h1-size;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
}

h2 {
    font-size: variable.$h2-size;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
}

h3 {
    font-size: variable.$h3-size;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
}

h4 {
    font-size: variable.$h4-size;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
}

h5 {
    font-size: variable.$h5-size;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
}

p {
    font-size: variable.$p-size;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
}

small {
    font-size: variable.$small-size;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
}

.text {
    &.black {
        color: variable.$black;
    }
    &.light-grey {
        color: variable.$light-grey;
    }
    &.muted {
        color: variable.$muted;
    }
    &.software {
        color: variable.$software;
    }
    &.agile {
        color: variable.$agile;
    }
    &.design {
        color: variable.$design;
    }

    &.bold {
        font-weight: 700;
    }    
    
    &.capitalize {
        text-transform: capitalize;
    }
    
    &.uppercase {
        text-transform: uppercase;
    }
}

.u {
    border-bottom-color: transparent;
    border-bottom-width: 3px;
    border-bottom-style: solid;
    font-weight: bold;

    &.software {
        border-bottom-color: variable.$software;
    }
    &.agile {
        border-bottom-color: variable.$agile;
    }
    &.design {
        border-bottom-color: variable.$design;
    }
    &.muted {
        border-bottom-color: variable.$muted;
    }
    &.light-grey {
        border-bottom-color: variable.$light-grey;
    }
}