@use "../../Variables.scss" as variable;

.resume {
    .loader {
        padding-top: 64px;
        width: 100%;
        display: flex;
        margin-top: 24px;
        justify-content: center;
    }

    .pager {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 32px;
        margin-bottom: 32px;

        button {
            background-color: transparent;
            border: none;
            cursor: pointer;
            outline: none;
            appearance: none;
            font-size: 24px;
            color: variable.$muted;

            &:hover {
                color: variable.$software!important;
            }
        }
    }

    .container {
        box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
        
        iframe, embed, img {
            width: 100%;
        }
    }

    .download-area {
        width: 100%;
        display: flex;
        margin-top: 24px;
        justify-content: center;
    }
}