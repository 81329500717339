@use "../../Variables.scss" as variable;

.skill-container {
    margin-bottom: 8px;

    display: flex;
    // justify-content: center;

    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px;
    gap: 16px;

    height: 80px;

    .kpi {

        &.software {
            border-color: variable.$software
        }

        &.agile {
            border-color: variable.$agile
        }

        &.design {
            border-color: variable.$design
        }

        color: variable.$muted;
        font-weight: 400;
        font-size: variable.$h3-size;
        line-height: 44px;
        /* Auto layout */

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        width: 72px;
        height: 72px;

        /* Inner Color */

        background: variable.$inner-color;
        /* Black */

        border: 2px solid variable.$black;
        // border-radius: 100%;

        /* Inside auto layout */

        flex: none;
        order: 0;
        flex-grow: 0;
    }

    .skill-text {
        padding: 4px 4px 4px 0px;
        width: 200px;

        .title {
            font-family: 'Montserrat', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: variable.$p-size;
            color: variable.$black;
        }

        .sub-title {
            font-family: 'Montserrat', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: variable.$small-size;;
            color: variable.$muted;
            text-transform: unset;
        }

    }
}