@use "../../Variables.scss" as variable;

.containt-item-container {
    
    color: inherit; /* blue colors for links too */
    text-decoration: inherit; /* no underline */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;

    .icon-border {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 5px;
        gap: 10px;
        color: variable.$black;

        width: 36px;
        height: 36px;
        min-width: 36px;
        /* Inner Color */

        background: variable.$inner-color;
        /* Black */

        border: 2px solid variable.$black;
        // border-radius: 59px;
    }

    .text {
        font-size: variable.$p-size;
        // width: 100px;;
    }
}