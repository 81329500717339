@use "../../Variables.scss" as variable;

.portfolio-card {

    .square {
        background-color: transparent;
        width: 16px;
        height: 16px;
        display: inline-block;
        margin-right: 4px;
    }

    &.software {
        .square {
            background-color: variable.$software;
        }
    }
    &.agile {
        .square {
            background-color: variable.$agile;
        }
    }
    &.design {
        .square {
            background-color: variable.$design;
        }
    }

    .project-content {
        padding: 12px 32px;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    width: 100%;
    color: inherit; /* blue colors for links too */
    text-decoration: inherit; /* no underline */

    &:hover {
        color: variable.$black!important;
    }

    // background-color: variable.$inner-color;
    // padding: 8px 16px;
    border-radius: 0.25em;
    // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    // border-bottom-color: variable.$background;
    // border-bottom-style: solid;
    // border-bottom-width: 4px;


    // border-top-color: variable.$background;
    // border-top-style: solid;
    // border-top-width: 3px;

    // &:hover {
    //     background-color: variable.$background;
    //     box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 25px;
    // }

    &.software:hover {
        border-top-color: variable.$software;
    }
    &.agile:hover {
        border-top-color: variable.$agile;
    }
    &.design:hover {
        border-top-color: variable.$design;
    }

    .content {
        font-size: variable.$small-size!important;
        color: variable.$black!important;
        p { margin-bottom: 0px; }
    }

    .title {
        margin-bottom: 0;    

        border-bottom-color: transparent;
        border-bottom-width: 3px;
        border-bottom-style: solid;
        text-transform: capitalize;
        width: fit-content;
        transition: all .25s ease-in-out;
        -moz-transition: all .25s ease-in-out;
        -webkit-transition: all .25s ease-in-out;

      
    }

    .subTitle {
        margin-top: 0;
    }

    &:hover .line-software {
        border-bottom-color: variable.$software
    }

    &:hover .line-agile {
        border-bottom-color: variable.$agile
    }

    &:hover .line-design {
        border-bottom-color: variable.$design
    }

    .link {
        a {
            color: inherit; /* blue colors for links too */
            text-decoration: inherit; /* no underline */
        
        }
        padding-bottom: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.project-section {

    // text-align: center;
    color: inherit; /* blue colors for links too */
    text-decoration: inherit; /* no underline */
    // margin-bottom: -32px;

    .title {
        border-bottom-color: transparent;
        border-bottom-width: 3px;
        border-bottom-style: solid;
        text-transform: capitalize;
        width: fit-content;

        &.line-software {
            border-bottom-color: variable.$software
        }

        &.line-agile {
            border-bottom-color: variable.$agile
        }

        &.line-design {
            border-bottom-color: variable.$design
        }

    }

    .sub-title {
        text-transform: uppercase;
    }

    .content {
        margin-top: 24px;
    }

    .link {
        padding-bottom: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}