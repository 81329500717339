@use "../../Variables.scss" as variable;

.embedded-markup {

    .title {
        border-bottom-color: transparent;
        border-bottom-width: 3px;
        border-bottom-style: solid;
        text-transform: capitalize;
        width: fit-content;
        margin-bottom: 0;

        h1,h2,h3,h4,h5,h6 {
            margin-bottom: 0;
        }

        &.line-software {
            border-bottom-color: variable.$software
        }

        &.line-agile {
            border-bottom-color: variable.$agile
        }

        &.line-design {
            border-bottom-color: variable.$design
        }

    }

    .subTitle {
        margin-top: 0;
        margin-bottom: 12px;
    }

    strong {
        border-bottom-color: transparent;
        border-bottom-width: 3px;
        border-bottom-style: solid;
        border-left-color: variable.$muted;
    }

    $highlight-padding: 24px;

    .highlight {
        border-left-color: variable.$muted;
        color: variable.$light-grey;
        border-left-width: 4px;
        border-left-style: solid;
        padding-left: $highlight-padding;
        padding-right: $highlight-padding;
        padding-top: $highlight-padding;
        padding-bottom: $highlight-padding;
        font-style: italic;
        font-size: variable.$h5-size;
    }
}



// .embedded-markup.software .highlight{
//     border-left-color: variable.$software
// }

// .embedded-markup.agile .highlight {
//     border-left-color: variable.$agile
// }

// .embedded-markup.design .highlight {
//     border-left-color: variable.$design
// }

.portfolio-footer {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.portfolio-divider {
    width: 100%;
}