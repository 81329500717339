@use "./../../Variables.scss" as variable;

.big-device-navbar {
    padding-right: 30px;
    display: none;
    @media (min-width: 993px) {
        display: block;
    }
}

.small-device-navbar {
    padding-right: 30px;
    padding-top: 15px;
    display: none;
    @media (max-width: 993px) {
        display: block;
    }
}

.burger-button {
    color: variable.$black;
    background: transparent;
    border: transparent;
    cursor: pointer;
}

.mr-navbar-link {
    color: variable.$black;
    border-top-width: 4px;
    border-top-style:solid;
    border-top-color: transparent;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-size: variable.$p-size;
    font-weight: 400;

    &:hover {
        border-top-color: variable.$muted;
        background-color: transparent!important;
    }
}

.mobile-link {
    display: block;
    font-size: 28px;
    
}

.link-menu {
    display: none;
    &.show {
        display: block;
    }
}

.overlay {
    background-color: variable.$background;
    opacity: 0.9;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 90000;
}

.link-section {
    background: transparent;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 90001;
    width: 100vw;
    text-align: center;
    padding-top: 20vh;
}