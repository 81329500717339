.timeline {

    .align-center {
        text-align: center;
    }

    .margin-top-0 {
        margin-top: 0;
    }

    .margin-bottom-0 {
        margin-bottom: 0;
    }

    .timeline-content {
        margin-top: 12px;
        margin-bottom: 12px;
        font-size: 12px;
    }
}