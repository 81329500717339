@use "./../../Variables.scss" as variable;

.mr-navbar {
    padding-top: 10px;
    padding-right: 5px;
    padding-left: 5px;

    &.mr-navbar-scrolled {
        // @media (max-width: 1300px) {
            -webkit-box-shadow: 0px 5px 19px 2px variable.$background; 
            box-shadow: 0px 5px 19px 2px variable.$background;
            background: variable.$background;
        // }
    }

    display: flex;
    justify-content: space-between;
    width: 100vw;
    align-content: center;

    .mr-navbar-logo {
        font-family: 'Quattrocento';
        font-style: normal;
        color: variable.$black;
        padding-left: 12px!important;
        &:hover {
            background-color: variable.$background!important;
        }
    }

    
}