@use "./Variables.scss" as variable;
@import 'w3-css';
@import 'font-awesome';

// @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&family=PT+Serif&family=Quattrocento&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&family=Montserrat&family=PT+Serif&family=Quattrocento&display=swap');
a,
a:link,
a:hover,
a:visited,
a:active,button
// ,div
{
    transition: all .25s ease-in-out;
    -moz-transition: all .25s ease-in-out;
    -webkit-transition: all .25s ease-in-out;

    &:hover {
        color: variable.$muted!important; 
    }

    &.agile:hover {
        color: variable.$agile!important; 
    }
    
    &.design:hover {
        color: variable.$design!important; 
    }
    
    &.software:hover {
        color: variable.$software!important; 
    }
}

body {
    font-family: 'Montserrat', sans-serif;
    // background: variable.$background;
    // these two lines corrects a bug from animate css: when a bound in animation is created whe total app width is grater than 100% and the background image is not displayed correctly
    width: 100vw!important;
    overflow-x: hidden;
}

body:before {
    content: ' ';
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    opacity: 0.7;
    background-image: url("/Images/background_1.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
  }

.page-content {
    margin-top: 80px;
}

.fa-anchor,.fa-coffee {font-size:200px}

.w3-button {
    color: variable.$black!important;
}

.w3-mr-software {
    text-decoration: none;
    color: variable.$inner-color;
    background-color: variable.$software!important;
}

.w3-mr-agile {
    text-decoration: none;
    color: variable.$inner-color;
    background-color: variable.$agile!important;    
}

.w3-mr-design {
    text-decoration: none;
    color: variable.$inner-color;
    background-color: variable.$design!important;
}

.w3-mr-black {
    text-decoration: none;
    color: variable.$inner-color!important;
    background-color: variable.$black!important;
}

.w3-mr-lifted {
    box-shadow: 0 2px 8px #6a6a6a66;
}

.logo-svg {
    .logo-fill {
        fill: variable.$inner-color!important; 
    }

    .logo-agile {
        stroke: variable.$agile!important; 
    }
    
    .logo-design {
        stroke: variable.$design!important; 
    }
    
    .logo-software {
        stroke: variable.$software!important; 
    }
}

.animate__animated.animate__delay-02s {
    animation-delay: 0.2s;
}

.animate__animated.animate__delay-04s {
    animation-delay: 0.4s;
}

.animate__animated.animate__delay-06s {
    animation-delay: 0.6s;
}

.animate__animated.animate__delay-08s {
    animation-delay: 0.8s;
}

.line {
    border-bottom-color: transparent;
    border-bottom-width: 3px;
    border-bottom-style: solid;
    width: fit-content;

    &.line-software {
        border-bottom-color: variable.$software
    }

    &.line-agile {
        border-bottom-color: variable.$agile
    }

    &.line-design {
        border-bottom-color: variable.$design
    }

}