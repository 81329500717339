@use "~sass-rem" as rem;

$black: #313131;
$muted: #4B5C71;
$light-grey: #5b5b5c;
$background: #EDEDEE;
$inner-color: #FFFFFF;

// ## Red, green and blue
$software: #a54c3c;
$agile: #1a6a6a;
$design: #557a61;

// 3 colori scelti in giappone
// $software: #2196F3;
// $agile: #4CAF50;
// $design: #673AB7;


// ## Red, blue, light blue
// $software: #DF3C5F;
// $agile: #224193;
// $design: #6F9BD1;

// ## Light blue, yellow and red
// $software: #81CAD6;
// $agile: #EDCD44;
// $design: #DC3E26;

// ## Light Rose, light yellow and dark rose
// $software: #e47188;
// $agile: #80C4B7;
// $design: #E3856B;

// ## Rose, violet and yellow
// $software: #3B5BA5;
// $agile: #E87A5D;
// $design: #F3B941;

// ## Rose, violet and yellow
// $software: #C7395F;
// $agile: #563675;
// $design: #f09d30;

// ## Blue, grey and yellow
// $software: #3b4d61;
// $agile: #be9e0e;
// $design: #2C306F;

$h1-size: rem.convert(67.68px);
$h2-size: rem.convert(50.52px);
$h3-size: rem.convert(37.9px);
$h4-size: rem.convert(28.43px);
$h5-size: rem.convert(21.33px);
$p-size: rem.convert(16px);
$small-size: rem.convert(12px);

$navbar-height: 61px;

:export {
    innerColor: $inner-color;
}