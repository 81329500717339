@use "../../Variables.scss" as variable;

.w3-mr-margin {
    margin-top: 4px;
    margin-right: 4px;
}

.mr-tag-software {
    background-color: variable.$software!important;
}
.mr-tag-agile {
    background-color: variable.$agile!important;
}
.mr-tag-design {
    background-color: variable.$design!important;
}
.mr-tag-black {
    background-color: variable.$black!important;
}
.mr-tag-white {
    background-color: variable.$inner-color!important;
    color: variable.$black!important;
    border-width: 2px;
    border-style: solid;
    border-color: variable.$background;
    // box-shadow: 2px 2px 0.5px 1px variable.$black;

}